<template>
    <div class="br-10 bs-036-29 border text-center d-middle-bt" @click="$emit('goTo')">
        <div class="d-middle">
            <img v-if="img" :src="img" alt="" width="60" height="60" class="wh-60" />
            <i v-else :class="`${icono} text-blue ${fIcono}`" />
            <p class="text-general ml-2 f-400 f-18">{{ titulo }}</p>
        </div>
        <div class="d-middle text-blue">
            <span class="f-22  f-600">{{ cantidad }}</span>
            <i v-if="action" class="icon-right-open ml-2" />
        </div>
    </div>
</template>
<script>
export default {
    props:{
        titulo: {
            type: String,
            required: true,
        },
        cantidad: {
            type: [String, Number],
            required: true,
        },
        img: {
            type: String,
            required: false,
        },
        icono: {
            type: String,
            required: false,
        },
        fIcono: {
            type: String,
            required: false,
            default: 'f-22'
        },
        action: {
            type: Boolean,
            default: false,
        },
    },
}
</script>
<style lang="scss">
</style>