import axios from 'axios';

const API = 'dashboard';

const ENDPOINTS = {

    getDashboard(){
        return axios.get(`${API}`)
    },

};

export default ENDPOINTS;